<template>
  <div>
    <h1>Welcome to PokerGO Studio.</h1>
    <p>If you have been requested to sign a release form please continue.</p>
    <p>
      If you are looking for PokerGO website please visit
      <a href="https://www.pokergo.com">https://pokergo.com</a>
    </p>
    <p>To continue please type in your release code below:</p>
    <div class="formitem">
      <input v-model="code" type="text" />
    </div>
    <div class="formitem">
      <button @click="releaseform">Continue</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      code: "",
    };
  },
  props: {
    msg: String,
  },
  methods: {
    releaseform() {
      console.log(this.code.toLowerCase());
      if (this.code != null && this.code.toLowerCase() === "release") {
        window.location =
          "https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=5a48d857-55ef-43fb-a324-1e6376444eee&env=na3&acct=9c619a7e-6c55-4797-a91e-691412a5be7e&v=2";
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.formitem {
  padding-top: 20px;
}
input {
  border: none;
  border-radius: 5px;
  font-weight: 600;
  height: 30px;
}
button {
  background: #9f26b5;
  padding: 13px 40px 10px 40px;
  width: 250px;
  border-radius: 50px;
  outline: none;
  border: none;
  color: #fff;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
