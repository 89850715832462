<template>
  <div class="home">
    <img alt="Pokergo Studio Release" src="../assets/pokergo.png" />
    <Release />
  </div>
</template>

<script>
// @ is an alias to /src
import Release from "@/components/Release.vue";

export default {
  name: "Home",
  components: {
    Release,
  },
};
</script>
<style scoped></style>
